
import constRouter from '@/constants/constRouter';
import { defineComponent } from 'vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { mapGetters } from 'vuex';
export default defineComponent({
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters({
      bankAccount: 'bank/getBankAccountList',
    }),
  },
  methods: {
    handleAddAccount() {
      this.$router.push({
        name: constRouter.BANK_LIST.name,
      });
    },
  },
});
