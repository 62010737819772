
import { defineComponent } from 'vue';
import IconPhone from '@/components/Icon/IconPhone.vue';
import IconFacebook from '@/components/Icon/IconFacebook.vue';
export default defineComponent({
  components: {
    IconPhone,
    IconFacebook,
  },
});
