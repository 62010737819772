
/*eslint-disable */
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ProfileOverview from './ProfileOverview.vue';
import IconCard from '@/components/Icon/IconCard.vue';
import IconChevronRight from '@/components/Icon/IconChevronRight.vue';
import IconChevronLeft from '@/components/Icon/IconChevronLeft.vue';
import IconSetting from '@/components/Icon/IconSetting.vue';
import IconShield from '@/components/Icon/IconShield2.vue';
import IconQuestion from '@/components/Icon/IconQuestion.vue';
import IconLogout from '@/components/Icon/IconLogout.vue';
import ProfileBankAccount from './ProfileBankAccount.vue';
import ProfileInfo from './ProfileInfo.vue';
import ProfileHelper from './ProfileHelp.vue';
import { listBankAccount } from '@/apis/ApiCustomer';
import { destroyToken, destroyRefreshToken } from '@/helpers/localStorage';
import { mapGetters, useStore } from 'vuex';
import constRouter from '@/constants/constRouter';
export default defineComponent({
  components: {
    DefaultLayout,
    ProfileOverview,
    ProfileBankAccount,
    ProfileInfo,
    ProfileHelper,
    IconCard,
    IconChevronRight,
    IconChevronLeft,
    IconSetting,
    IconShield,
    IconQuestion,
    IconLogout,
  },
  setup() {
    const store = useStore();
    const fetchBankAccountList = () => {
      listBankAccount()
        .then((res) => {
          store.commit('bank/setBankAccountList', res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchBankAccountList();
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  data() {
    return {
      active: false,
      comp: -1,
    };
  },
  methods: {
    handleBack() {
      this.active = false;
    },
    handlelClick(comp: number) {
      this.comp = comp;
      this.active = true;
    },
    handlelEkyc() {
      this.$router.push({
        name: constRouter.EKYC.name,
      });
    },
    handleLogout() {
      destroyToken();
      destroyRefreshToken();
      this.$router.push({
        name: constRouter.REGISTER_PHONE.name,
      });
    },
  },
});
