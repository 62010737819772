<template>
  <div class="profile-overview bg-primary text-white relative">
    <h2>{{ user.fullName }}</h2>
    <p class="tab-inactive">{{ user.phoneNumber }}</p>
    <div class="profile-overview-robot absolute">
      <img src="@/assets/images/app_logo_rolate.png" class="relative z-10" />
      <div class="blur"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
});
</script>

<style lang="scss" scoped>
.profile-overview {
  height: 110px;
  border-radius: 25px;
  padding: 30px 25px;
  overflow: hidden;
  .profile-overview-robot {
    width: 136px;
    right: -20px;
    top: 8px;
    z-index: 1;
  }
  .blur {
    position: absolute;
    width: 194px;
    height: 194px;
    background: #def5e9;
    border-radius: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    filter: blur(70px);
    -webkit-filter: blur(40px);
  }
}
</style>
